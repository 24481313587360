<template>
    <div :key="pageKey">
        <appic-page-title-bar ref="breadcrumbBar" help-page-component="SuppliersOverview"></appic-page-title-bar>
        <app-section-loader :status="loader"></app-section-loader>
        <v-container fluid grid-list-xl pb-0 pt-0 pl-5 pr-4>
            <v-layout row wrap>
                <app-card :fullBlock="true" colClasses="xl12 lg12 md12 sm12 xs12">
                    <SuppliersListing/>
                </app-card>
            </v-layout>
        </v-container>
    </div>
</template>

<script>
    import LoadingComponent from "Components/Appic/LoadingComponent";
    import {mapFields} from "vuex-map-fields";

    const SuppliersListing = () => ({
        component: import("Components/Appic/SuppliersListing"),
        loading: LoadingComponent
    })
    const ExportTable = () => import("Components/Appic/ExportTable");

    export default {
        name: "SuppliersOverview",
        components: {SuppliersListing, ExportTable},
        title: '',
        data() {
            return {
                loader: false,
                pageKey: Math.floor(Math.random() * 100),
            }
        },
        computed: {
            ...mapFields('runtime',{
                openedTabs: 'openedTabs'
            }),
        },
        methods: {
            newSupplier () {
                let tab = window.open('/v1/suppliers/add','_blank')
                tab.name = 'tab_' + Math.floor(Math.random() * 10000)
                this.openedTabs.push(tab.name)
            }
        }
    }
</script>

<style>
    .font-xs {
        font-size: 0.65rem;
    }
    .mt-5px {
        margin-top: 5px !important;
    }
    .mt-6px {
        margin-top: 6px !important;
    }
</style>
